.navbar {
  overflow: hidden;
  background-color: #666; 
}

.navbar a {
  float: left;
  font-size: 16px;
  color: white;
  text-align: center;
  padding: 10px 10px;
  text-decoration: none;
}

.subnav {
  float: left;
  overflow: hidden;
}

.subnav .subnavbtn {
  font-size: 16px;  
  border: none;
  outline: none;
  color: white;
  padding: 14px 16px;
  background-color: inherit;
  font-family: inherit;
  margin: 0;
}

.navbar a:hover, .subnav:hover .subnavbtn {
  background-color: #b8624d;
}

.subnav-content {
  display: none;
  position: absolute;
  left: 0;
  background-color: #b8624d;
  width: 100%;
  z-index: 1;
  height: 60px;
}

.subnav-content a {
  float: left;
  color: white;
  text-decoration: none;
  
}

.subnav-content a:hover {
  background-color: #eee;
  color: grey;
}

.subnav:hover .subnav-contessnt {
  display: block;
}







.luxbar-default {
    width: 100%;
    position: relative;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24); }
  

 
  .luxbar-static {
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
    width: 100%;
    position: absolute;
    top: 0;
    left: 0; 
    z-index: 1000;}
    .luxbar-static .luxbar-checkbox:checked ~ .luxbar-menu {
      position: absolute; }
  
  .luxbar-fixed {
    width: 100%;
    position: fixed; left: 0; top: 0;
    z-index: 100;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24); }
  
  .luxbar-fixed-bottom {
    width: 100%;
    position: fixed;
    bottom: 0;
    left: 0;
    z-index: 0;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24); }
  
  .luxbar-hamburger span, .luxbar-hamburger span::before, .luxbar-hamburger span::after {
    display: block;
    height: 2px;
    width: 26px;
    transition: 0.6s ease; }
  
  .luxbar-checkbox:checked ~ .luxbar-menu li .luxbar-hamburger span {
    background-color: transparent; }
    .luxbar-checkbox:checked ~ .luxbar-menu li .luxbar-hamburger span::before, .luxbar-checkbox:checked ~ .luxbar-menu li .luxbar-hamburger span::after {
      margin-top: 0; }
  
  .luxbar-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: 58px; }
  
  .luxbar-menu-left .luxbar-navigation, .luxbar-menu-left .luxbar-header {
    justify-content: flex-start; }
  
  .luxbar-menu-right .luxbar-hamburger {
    margin-left: auto; }
  
  .luxbar-brand {
    font-size: 1.2em;
    padding: 10px 12px 10px 12px;
    line-height: 100%; }
  
  .luxbar-menu {
    min-height: 58px;
    transition: 0.6s ease;
    width: 100%; }
  
  .luxbar-navigation {
    display: flex;
    flex-direction: column;
    list-style: none;
    padding-left: 0;
    margin: 0; }
  
  .luxbar-menu a,
  .luxbar-item a {
    text-decoration: none;
    color: inherit;
    cursor: pointer; }
  
  .luxbar-item {
    height: 58px; 
    line-height: 100%;
  }
    .luxbar-item a {
      padding: 18px 24px 18px 24px;
      display: block; }
  
  .luxbar-hamburger {
    padding: 18px 24px 18px 24px;
    position: relative;
    cursor: pointer; }
    .luxbar-hamburger span::before, .luxbar-hamburger span::after {
      content: '';
      position: absolute; }
    .luxbar-hamburger span::before {
      margin-top: -8px; }
    .luxbar-hamburger span::after {
      margin-top: 8px; }
  
  .luxbar-checkbox {
    display: none; }
    .luxbar-checkbox:not(:checked) ~ .luxbar-menu {
      overflow: hidden;
      height: 58px; }
    .luxbar-checkbox:checked ~ .luxbar-menu {
      transition: height 0.6s ease;
      height: 100vh;
      overflow: auto; }
  
  .dropdown {
    position: relative;
    height: auto;
    min-height: 58px; }
    .dropdown:hover > ul {
      position: relative;
      display: block;
      min-width: 100%; }
    .dropdown > a::after {
      position: absolute;
      content: '';
      right: 10px;
      top: 25px;
      border-width: 5px 5px 0;
      border-color: transparent;
      border-style: solid; }
    .dropdown > ul {
      display: block;
      overflow-x: hidden;
      list-style: none;
      padding: 0; }
      .dropdown > ul .luxbar-item {
        min-width: 100%;
        height: 29px;
        padding: 5px 10px 5px 40px; }
        .dropdown > ul .luxbar-item a {
          min-height: 29px;
          line-height: 29px;
          padding: 0; }
  .compLogo{
    position: relative;
    height: 70px;
    top: 5px;
    z-index: 0;
  }
  @media screen and (min-width: 768px) {
    .compLogo{
      position: relative;
      top: 5px;
      height: 90px;
      z-index: 0;
    }  
    .luxbar-navigation {
      flex-flow: row;
      justify-content: flex-end; }
  
    .luxbar-hamburger {
      display: none; }
  
    .luxbar-checkbox:not(:checked) ~ .luxbar-menu {
      overflow: visible; }
    .luxbar-checkbox:checked ~ .luxbar-menu {
      height: 58px; }
  
    .luxbar-menu .luxbar-item {
      border-top: 0; }
  
    .luxbar-menu-right .luxbar-header {
      margin-right: auto; }
  
    .dropdown {
      height: 58px; }
      .dropdown:hover > ul {
        position: absolute;
        left: 0;
        top: 58px;
        padding: 0; }
      .dropdown > ul {
        display: none; }
        .dropdown > ul .luxbar-item {
          padding: 5px 10px; }
          .dropdown > ul .luxbar-item a {
            white-space: nowrap; } }
  .luxbar-checkbox:checked + .luxbar-menu .luxbar-hamburger-doublespin span::before {
    transform: rotate(225deg); }
  .luxbar-checkbox:checked + .luxbar-menu .luxbar-hamburger-doublespin span::after {
    transform: rotate(-225deg); }
  .luxbar-checkbox:checked + .luxbar-menu .luxbar-hamburger-spin span::before {
    transform: rotate(45deg); }
  .luxbar-checkbox:checked + .luxbar-menu .luxbar-hamburger-spin span::after {
    transform: rotate(-45deg); }
  
  /******* color variables *******/
  /******* default dark *******/
  .luxbar-menu-dark,
  .luxbar-menu-dark .dropdown ul {
    background-color: #212121;
    color: #fff; }
  
  .luxbar-menu-dark .active,
  .luxbar-menu-dark .luxbar-item:hover {
    background-color: #424242; }
  .luxbar-menu-dark .luxbar-hamburger span,
  .luxbar-menu-dark .luxbar-hamburger span::before,
  .luxbar-menu-dark .luxbar-hamburger span::after {
    background-color: #fff; }
  
  /******* default light *******/
  .luxbar-menu-light,
  .luxbar-menu-light .dropdown ul {
    background-color: #e0e0e0;
    color: #212121; }
  
  .luxbar-menu-light .active,
  .luxbar-menu-light .luxbar-item:hover {
    background-color: #bdbdbd; }
  .luxbar-menu-light .luxbar-hamburger span,
  .luxbar-menu-light .luxbar-hamburger span::before,
  .luxbar-menu-light .luxbar-hamburger span::after {
    background-color: #212121; }
  
  /******* default material-red *******/
  .luxbar-menu-material-red,
  .luxbar-menu-material-red .dropdown ul {
    background-color: #b71c1c;
    color: #fff; }
  
  .luxbar-menu-material-red .active,
  .luxbar-menu-material-red .luxbar-item:hover {
    background-color: #c62828; }
  .luxbar-menu-material-red .luxbar-hamburger span,
  .luxbar-menu-material-red .luxbar-hamburger span::before,
  .luxbar-menu-material-red .luxbar-hamburger span::after {
    background-color: #fff; }
  
  /******* default material-indigo *******/
  .luxbar-menu-material-indigo,
  .luxbar-menu-material-indigo .dropdown ul {
    background-color: #1a237e;
    color: #fff; }
  
  .luxbar-menu-material-indigo .active,
  .luxbar-menu-material-indigo .luxbar-item:hover {
    background-color: #283593; }
  .luxbar-menu-material-indigo .luxbar-hamburger span,
  .luxbar-menu-material-indigo .luxbar-hamburger span::before,
  .luxbar-menu-material-indigo .luxbar-hamburger span::after {
    background-color: #fff; }
  
  /******* default material-PowerPoint Orange *******/
  .luxbar-menu-material-powerpoint,
  .luxbar-menu-material-powerpoint .dropdown ul {
    background-color: #bb492d;
    color: #fff; }
  
  .luxbar-menu-material-powerpoint .active,
  .luxbar-menu-material-powerpoint .luxbar-item:hover {
    background-color: #b8624d; }
  .luxbar-menu-material-powerpoint .luxbar-hamburger span,
  .luxbar-menu-material-powerpoint .luxbar-hamburger span::before,
  .luxbar-menu-material-powerpoint .luxbar-hamburger span::after {
    background-color: #fff; }

  /******* default material-green *******/
  .luxbar-menu-material-green,
  .luxbar-menu-material-green .dropdown ul {
    background-color: #1b5e20;
    color: #fff; }
  
  .luxbar-menu-material-green .active,
  .luxbar-menu-material-green .luxbar-item:hover {
    background-color: #2e7d32; }
  .luxbar-menu-material-green .luxbar-hamburger span,
  .luxbar-menu-material-green .luxbar-hamburger span::before,
  .luxbar-menu-material-green .luxbar-hamburger span::after {
    background-color: #fff; }
  
  /******* default material-amber *******/
  .luxbar-menu-material-amber,
  .luxbar-menu-material-amber .dropdown ul {
    background-color: #ff6f00;
    color: #fff; }
  
  .luxbar-menu-material-amber .active,
  .luxbar-menu-material-amber .luxbar-item:hover {
    background-color: #ff8f00; }
  .luxbar-menu-material-amber .luxbar-hamburger span,
  .luxbar-menu-material-amber .luxbar-hamburger span::before,
  .luxbar-menu-material-amber .luxbar-hamburger span::after {
    background-color: #fff; }
  
  /******* default material-brown *******/
  .luxbar-menu-material-brown,
  .luxbar-menu-material-brown .dropdown ul {
    background-color: #3e2723;
    color: #fff; }
  
  .luxbar-menu-material-brown .active,
  .luxbar-menu-material-brown .luxbar-item:hover {
    background-color: #4e342e; }
  .luxbar-menu-material-brown .luxbar-hamburger span,
  .luxbar-menu-material-brown .luxbar-hamburger span::before,
  .luxbar-menu-material-brown .luxbar-hamburger span::after {
    background-color: #fff; }
  
  /******* default material-bluegrey *******/
  .luxbar-menu-material-bluegrey,
  .luxbar-menu-material-bluegrey .dropdown ul {
    background-color: #263238;
    color: #fff; }
  
  .luxbar-menu-material-bluegrey .active,
  .luxbar-menu-material-bluegrey .luxbar-item:hover {
    background-color: #37474f; }
  .luxbar-menu-material-bluegrey .luxbar-hamburger span,
  .luxbar-menu-material-bluegrey .luxbar-hamburger span::before,
  .luxbar-menu-material-bluegrey .luxbar-hamburger span::after {
    background-color: #fff; }
  
  /******* default material-cyan *******/
  .luxbar-menu-material-cyan,
  .luxbar-menu-material-cyan .dropdown ul {
    background-color: #006064;
    color: #fff; }
  
  .luxbar-menu-material-cyan .active,
  .luxbar-menu-material-cyan .luxbar-item:hover {
    background-color: #00838f; }
  .luxbar-menu-material-cyan .luxbar-hamburger span,
  .luxbar-menu-material-cyan .luxbar-hamburger span::before,
  .luxbar-menu-material-cyan .luxbar-hamburger span::after {
    background-color: #fff; }
  
  /*# sourceMappingURL=luxbar.css.map */
  