.headerDiv {
    position: fixed;
    top: 60px;
    left: 0px;
    font-size: 1.2em;
    font-family: Arial, Helvetica, sans-serif;
    text-align: center;
  }

.headerContainer {
    position: fixed;
    top: 58px;
    width: 100%;
    z-index: -50;
  }
  
.headerCenter {
        position: absolute;
        top: 25%;
        left: 50%;
        transform: translate(-50%, -50%);
        text-align: center;
        text-shadow: 2px 2px rgb(130, 130, 130);
        color: white;
  }
  .mainCenter {
    margin-top: -100px;
    left: 50%;
    font-weight: 700;
    text-align: center;
    text-shadow: 2px 2px rgb(130, 130, 130);
    color: white;
    font-size: 2em;
}

  .mainImg { 
    width: 110%;
    height: 200px;
    object-fit:cover;
    margin-left: -10px;
    margin-top: -10px;
  }

.headerImage { 
    width: 100%;
    height: 200px;
    object-fit: cover;
    opacity: 0.5;
  }

.headerH2{
    @media (min-width: 786px) {
        display: hidden;
    }



}

@media (max-width: 786px) {

  .mainCenter {
    margin-top: -100px;
    left: 50%;
    font-weight: 700;
    font-size: 1.5em;
    text-shadow: 1px 1px rgb(130, 130, 130);
    color: white;
  }
}
  
