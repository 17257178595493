.templatesH1 {
  position: relative;
  top: 60px;
  font-size: 2.5em;
  font-weight: 700;
  text-align: center;
  z-index: 0;
}

.templatesH2 {
  position: relative;
  top: 100px;
  font-size: 1.5em;
  font-weight: 300;
  text-align: center;
  z-index: 0;
}

.templateBox {
  position: relative;
  background-color: rgba(211, 149, 107, 0);
  width: 60%;
  margin-left: 20%;
  margin-right: 20%;
  margin-top: 80px;
  text-align: center;
}

.header-cell {
  background-color: rgb(220, 220, 220);
  text-align: center;
}

.header-cell-letgo {
  background-color: rgb(220, 220, 220);
  text-align: center;
  width: 150px;
}

.rows {
  width: 500px;
}

.table {
  width: 100%;
  margin-bottom: 1rem;
  color: #212529;
  border-collapse: collapse;
}

.table td,
.table th {
  padding: .75rem;
  vertical-align: middle;
  border-top: 1px solid #dee2e6;
}

.tablerelative {
  position: relative;
}

.tablecentered {
  margin: 0 auto;
}

td {
  display: table-cell;
  vertical-align: middle;
}

.imageCell {
  width: 200px;
}

.descCell {
  width: auto;
  text-align: left;
  vertical-align: middle;
  font-size: large;
  font-style: normal;
}

.byCell {
  width: 150px;
  vertical-align: middle;
  text-align: left;
  color: blueviolet;
}

.Announcements {
  position: absolute;
  top: 150px;
  width: 100%;
  text-align: center;
  align-content: center;
  align-items: center;
}

.ChromaKeys {
  position: absolute;
  top: 150px;
  width: 100%;
  text-align: center;
}

.Presentation {
  position: absolute;
  top: 150px;
  width: 100%;
  text-align: center;
}

.Worship {
  position: absolute;
  top: 150px;
  width: 100%;
  text-align: center;
}

#fade {
  display: none;
  height: 3px;
  transition: opacity 1s;
}

#fadeOff {
  display: none;
  transition: opacity 1s;
}

/* body {
    background: #ece5da;
    font-family: "Raleway", sans-serif;
    color: ivory;
    font-size: 20px;
  } */

nav {
  margin: auto;
  margin-top: 40px;
  position: relative;
  width: clamp(400px, 50vw, 100%);
}

nav h2 {
  font-size: 1.5rem;
  border-radius: 2px;
  position: relative;
  background: tomato;
  height: 40px;
  text-transform: uppercase;
  margin-bottom: 0;
  color: inherit;
  font-weight: 200;
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  box-shadow: 4px 4px 20px -2px rgba(0, 0, 0, 0.35);
  transition: all 0.4s;
}

nav:hover h2 {
  transform: translateY(-2px);
  box-shadow: 2px 2px 5px -1px rgba(0, 0, 0, 0.35);
  transistion: all 0.4s;
}

#toggle {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  z-index: 1;
  opacity: 0;
  cursor: pointer;
  height: 40px;
}

h2::before {
  position: absolute;
  right: 0;
  top: 0;
  height: inherit;
  aspect-ratio: 1;
  background: currentColor;
  display: flex;
  align-items: center;
  padding-right: 1rem;
  content: "";
  clip-path: polygon(50% 25%, 20% 80%, 80% 80%);
  transform: rotate(180deg) scale(0.75);
}

#toggle:not(:checked)~h2::before {
  transform: rotate(0deg) scale(0.75);
}

#toggle:focus~h2 {
  background: mediumorchid;
  transition: background 0.45s;
}

#toggle:checked~ul {
  visibility: hidden;
  opacity: 0;
}

#toggle:not(:checked)~ul {
  opacity: 1;
  transition: opacity 0.3s ease-in-out;
}


nav ul {
  padding-left: 0;
  padding-top: 1rem;
  margin-top: 0;
  background: #ece5da;
  list-style: none;
  overflow: hidden;
  text-align: right;
  text-align: center;
  transition: all 0.4s ease-out;
  width: 100%;
  position: absolute;
}

nav ul li {
  border-radius: 2px;
  position: relative;
  display: inline-block;
  line-height: 1.5;
  width: 100%;
  margin: 0 0 0.25rem 0;
  background: tomato;
  transition: background 3s;
  box-shadow: 2px 2px 10px -2px rgba(0, 0, 0, 0.35);
}

nav ul li:hover,
nav ul li a:focus {
  background: mediumorchid;
  transition: background 0.45s;
}

nav ul a {
  display: block;
  color: inherit;
  text-transform: lowercase;
  font-weight: 200;
  text-decoration: none;
}


.container {
  max-width: 55ch;
  margin: auto;
  color: #36454F;
}

.button {
  background-color: #b86229;
  border: none;
  color: white;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  cursor: pointer;
}

.buttonbar {
  background-color: #b86229;
  border: none;
  color: white;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  cursor: pointer;
}

.themedBack {
  background-color: rgba(211, 149, 107, 0.959);
}

@media (max-width: 786px) {

  .descCell {
    width: auto;
    text-align: center;
    vertical-align: bottom;
    font-size: small;
    color: white;
    text-shadow: 2px 2px grey;
    position: relative;
    left: -200px;
    font-style: normal;


  }

  .byCell {
    display: none;
  }

  .header-cell {
    display: none;
  }

  .header-cell-letgo {
    display: none;
  }
  table {
    font-size: 1em;
    width: 100%;
  }

}