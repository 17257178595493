  /*Social Links*/
  .social-links-ul {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
  }
  /*Change icons size here*/
  .social-icons .fa {
    font-size: 1.8em;
  }
  /*Change icons circle size and color here*/
  .social-icons .fa {
    width: 50px;
    height: 50px;
    line-height: 50px;
    text-align: center;
    color: #fff;
    color: rgba(255, 255, 255, 0.8);
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
  }
  
  .social-icons.icon-circle .fa {
    border-radius: 50%;
  }
  .social-icons.icon-rounded .fa {
    border-radius: 5px;
  }
  .social-icons.icon-flat .fa {
    border-radius: 0;
  }
  
  .social-icons .fa:hover,
  .social-icons .fa:active {
    color: #fff;
    -webkit-box-shadow: 1px 1px 3px #333;
    -moz-box-shadow: 1px 1px 3px #333;
    box-shadow: 1px 1px 3px #333;
    opacity: 0.7;
  }
  .social-icons.icon-zoom .fa:hover,
  .social-icons.icon-zoom .fa:active {
    -webkit-transform: scale(1.1);
    -moz-transform: scale(1.1);
    -ms-transform: scale(1.1);
    -o-transform: scale(1.1);
    transform: scale(1.1);
  }
  .social-icons.icon-rotate .fa:hover,
  .social-icons.icon-rotate .fa:active {
    -webkit-transform: scale(1.1) rotate(360deg);
    -moz-transform: scale(1.1) rotate(360deg);
    -ms-transform: scale(1.1) rotate(360deg);
    -o-transform: scale(1.1) rotate(360deg);
    transform: scale(1.1) rotate(360deg);
  }
  
  .social-icons .fa-twitter {
    background-color: #32ccfe;
  }
  .social-icons .fa-instagram {
    background-color: #f10619;
  }
  .social-icons .fa-facebook {
    background-color: #3c599f;
  }
  /* .social-icons .fa-youtube {
    background-color: #c52f30;
  } */
  .social-icons .fa-phone {
    background-color: green;
  }
  .social-icons .fa-map-marker {
    background-color: grey;
  }

  @media (max-width: 786px) {
} 
@keyframes loading {
  100% {
    transform: translateX(100%);
  }
}
  
.fixed_footer{
    width: 100%;
    height: 50px;
    background: #bb492d;
    position: absolute; left: 0; bottom: 0;
    font-size: 1.2em;
    font-family: Arial, Helvetica, sans-serif;
    z-index: 100;
    text-align: center;
    color: white;
}
.fixed_footer a {
    color: white;
}
.fixed_footer p{
    color: #696969;
    column-count: 2;
    column-gap: 50px;
    font-size: .5em;
    font-weight: 300;
}

.footer{
  width: 100%;
  height: 50px;
  background: #bb492d;
  font-size: 1.2em;
  font-family: Arial, Helvetica, sans-serif;
  text-align: center;
  color: white;
  display: block;
}
.footer a {
  color: white;
}
.footer p{
  color: #696969;
  column-count: 2;
  column-gap: 50px;
  font-size: .5em;
  font-weight: 300;
}