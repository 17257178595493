.downloadBoxSectionNoDisplay{
    display: none;
}

.downloadBoxSection{
    display: block;
    margin-top: 20px;
    border-color: black;
    border-width: 2px;
    border-style: solid;
    border-radius: 5px;
    text-align: center;
    align-content: center;
    position: relative;
    align-items: center;
}