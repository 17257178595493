.faqsH1{
    position: relative;
    top: 60px;
    font-size: 2.5em;
    font-weight: 700;
    text-align: center;
    z-index: 0;
}
.faqsH2{
    position: relative;
    top: 100px;
    font-size: 1.5em;
    font-weight: 300;
    text-align: center;
    z-index: 0;
}
p{
    font-size: .75em;
    font-weight: 100;
    text-align: left;
}

.faqBox{
    background-color: rgb(255,255,255);
    width: 60%;
    margin-left: 20%;
    margin-right: 20%;
    margin-top: 150px;
    text-align: center;
}

.header-cell{
    background-color: rgb(220,220,220);
    text-align: center;
}

.rows{
    width: 500px;
}


