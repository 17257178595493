.highlighsDiv {
    align-items: center;
    align-self: center;
    width: 100%;
    text-align: center;
  }

  .highlightsContainer {
    position: fixed;
    top: 58px;
    width: 100%;
    z-index: -50;
  }

  .grid-container {
    display: grid;
    grid-template-columns: auto auto;
    background-color: #FFFFFF;
    padding: 100px;
  }
  .grid-itemBlank {
    background-color: rgba(255, 255, 255, 0.8);
    border: 0px solid rgba(0, 0, 0, 0.8);
    padding: 2px;
  }
  .grid-item1 {
    background-color: rgba(255, 255, 255, 0.8);
    border: 0px solid rgba(0, 0, 0, 0.8);
    padding: 20px;
    font-size: 30px;
  }
  .grid-item2 {
    background-color: rgba(255, 255, 255, 0.8);
    border: 0px solid rgba(0, 0, 0, 0.8);
    padding: 20px;
    font-size: 30px;
    text-align: left;
  }
  .imgDiv{
    display: flex;
    justify-content: center;
  }

  .easyImage {
    height: 300px;
    padding: 5px;
  }

  p {
    text-align: center;
    font-family:Arial, Helvetica, sans-serif;
    font-size: 3em;
    width: 100%;
  }
  .grid-container {
    display: grid;
    grid-template-columns: auto auto;
    grid-gap: 10px;
    padding: 10px;
  }

  .grid-container > div {
    background-color: rgba(255, 255, 255, 0.8);
    padding: 10px 0;
    font-size: 30px;
  }
  .item1{
    grid-column: 1 / 2;
  }

  .easyh2{
    font-size: 2.3em;
    text-align: center;
    font-family:Arial, Helvetica, sans-serif;
    width: 100%;
    font-weight: 900;

  }
  .easyOL{
    font-size: 1.2em;
    text-align: left;
    
  }
  .text1{ 
    color: black; 
    font-size: 1em; 
    font-weight: 500; 
    letter-spacing: 2px; 
    margin-bottom: 10px; 
    animation: flash1 5s 1; 
} 

@keyframes flash1 {
  0% { color: black; }
  60% {color: orangered}
  100% { color: black; }
}
.text2{ 
  color: black; 
  font-size: 1em; 
  font-weight: 500; 
  letter-spacing: 2px; 
  margin-bottom: 10px; 
  animation: flash1 5s 1; 
  animation-delay: 5s;
} 

.text3{ 
  color: black; 
  font-size: 1em; 
  font-weight: 500; 
  letter-spacing: 2px; 
  margin-bottom: 10px; 
  animation: flash1 5s 1; 
  animation-delay: 10s;
} 

  @media (max-width: 786px) {

    .easyh2{
      font-size: 1.5em;
      text-align: center;
      font-family:Arial, Helvetica, sans-serif;
      width: 100%;
    }
    .easyOL{
      font-size: 1em;
    }
    .easyImage {
      height: 150px;
      padding: 5px;
    }
    .text1{ 
      color: black; 
      font-size: .7em; 
      font-weight: 500; 
      letter-spacing: 2px; 
      margin-bottom: 10px; 
      animation: flash1 5s 1; 
  } 
  .text2{ 
    color: black; 
    font-size: .7em; 
    font-weight: 500; 
    letter-spacing: 2px; 
    margin-bottom: 10px; 
    animation: flash1 5s 1; 
    animation-delay: 5s;
  } 
  
  .text3{ 
    color: black; 
    font-size: .7em; 
    font-weight: 500; 
    letter-spacing: 2px; 
    margin-bottom: 10px; 
    animation: flash1 5s 1; 
    animation-delay: 10s;
  } 
  
  }


  
