.mainDiv {
    top: 75px;
    left: 0px;
    font-size: 1.2em;
    font-family: Arial, Helvetica, sans-serif;
    text-align: center;
  }

  .mainContainer {
    top: 75px;
    width: 100%;
    padding: 0px;
  }

  .highlightsDiv {
    align-items: center;
    align-self: center;
    width: 100%;
    text-align: center;
  }




