.highlighsDiv {
    align-items: center;
    align-self: center;
    width: 100%;
    text-align: center;
    margin: 10px auto;
  }

  .highlightsContainer {
    position: fixed;
    top: 58px;
    width: 100%;
    z-index: -50;
  }
  .imgDiv{
    display: flex;
    justify-content: center;
  }


  img {
    height: 160px;
    padding: 10px;
  }

  p {
    text-align: center;
    font-family:Arial, Helvetica, sans-serif;
    font-size: 1em;
    width: 100%;
  }


  .highlightsh2{
    margin-top: 20px;
    font-size: 2em;
    text-align: center;
    font-family:Arial, Helvetica, sans-serif;
    font-weight: 900;
    width: 100%;
  }
  .highlightsSection{
    background-color: rgba(211, 149, 107, 0.959);
    margin: 10px auto;
  }
  .highlight-flex-container {
    display: flex;
    flex-wrap: wrap;
    background-color: rgba(211, 149, 107, 0.959);
    flex-direction: row;
    -ms-flex-align: center;
    text-align: center;
    align-items: center;
    justify-content: center;
  }
  
  .highlight-flex-container > div {
    background-color: #f1f1f1;
    flex-wrap: wrap;
    margin: 10px;
    padding: 20px;
    font-size: 30px;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }

  @media (max-width: 786px) {

    .highlighsDiv p {
      align-items: center;
      align-self: center;
      width: 100%;
      text-align: center;
      font-size: .5em;
    }

    .highlightsh2{
      margin-top: 1.5em;
      font-size: 1.5em;
      text-align: center;
      font-family:Arial, Helvetica, sans-serif;
      font-weight: 900;
      width: 100%;
      }
      .highlight-flex-container {
        display: flex;
        flex-wrap: wrap;
        background-color: rgba(211, 149, 107, 0.959);
        flex-direction: column;
        align-items: center;
        justify-content: center;
      }
      
      .highlight-flex-container > div {
        background-color: #f1f1f1;
        flex-wrap: wrap;
        margin: 10px;
        padding: 20px;
        font-size: 30px;
        flex-direction: column;
        align-items: center;
        justify-content: center;
      }

    }


  
