table {
    font-family: Arial, Helvetica, sans-serif;
    border-collapse: collapse;
    width: 80%;
    margin-top: 5px;
    margin-left: auto; 
    margin-right: auto;
    font-size: 2em;
  }
  
  table td, table th {
    border: 1px solid #dddddd;
    padding: 8px;
    width: 25%;
  }
  
  table tr:nth-child(even){background-color: #bbbbbb;}
  
  table tr:nth-child(odd){background-color: #dddddd;}

  table tr:hover {background-color: rgba(211,149,107,.959);}
  
  table th {
    padding-top: 12px;
    padding-bottom: 12px;
    text-align: center;
    background-color: rgba(190,128,87,.959);
    color: white;
  }

  button {
    background-color:#bb492d;
    font-size: 1.5em;

  }

  .pagination{
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    flex-direction: row;
    margin-top: 5px;
  }

  #filterspan{
    margin-top: 90px;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    font-size: 1.5em;
    display: flex;
    justify-content: center;
  }
  .themedBack{
    background-color: rgba(211, 149, 107, 0.959);
    align-items: center;
    margin-top: -50px;
  }

  input{
    align-self: center;
    justify-self: center;
  }
  .tdimage {
    padding: 6px;
    align-self: center;
  }

  .centerdiv{
    margin-left: auto;
    margin-right: auto;
  }

  image {
    align-self: center;
    padding: 6px;
  }
  
  @media (max-width: 786px) {

    table {
      font-size: .8em;
      width: 100%;
    }

    table img {
      width: 100%;
      height: 100%;
      align-self: center;
      padding: 1px;
    }

    .tdimage {
      padding: 2px;
    }
    
    image {
      padding: 2px;
      align-self: center;
    }

    table td, table th {
      border: 1px solid #dddddd;
      padding: 1px;
      width: 25%;
    }


  }