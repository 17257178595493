.exampleSection {
    background-color: rgba(211, 149, 107, 0.959);
    padding-top: 10px;
    padding-bottom: 10px;
    margin-bottom: 60px;
}
  .example-flex-container {
    display: flex;
    background-color: rgba(211, 149, 107, 0.959);
  }
  
  .example-flex-container > div {
    background-color: #f1f1f1;
    margin: 50px;
    padding: 20px;
    font-size: 30px;
  }

  @media (max-width: 786px) {

    .exampleSection {
      display: none;
  }


  }