
.grid-container {
    display: grid;
    grid-template-columns: auto auto;
    background-color: #FFFFFF;
    padding: 10px;
  }
  .grid-item {
    background-color: rgba(255, 255, 255, 0.8);
    border: 0px solid rgba(0, 0, 0, 0.8);
    padding: 20px;
    font-size: 30px;
    text-align: center;
  }

.buyH2 {
    font-size: 1.1em;
    text-align: center;
    font-family:Arial, Helvetica, sans-serif;
    font-weight: 900;
    width: 100%;
    vertical-align: middle;
}

.buyH3{
        font-size: 1.0em;
        text-align: center;
        font-family:Arial, Helvetica, sans-serif;
        font-weight: 500;
        width: 100%;
        vertical-align: middle;
}

.buyButton{
    background-color: white !important;
    background-image: url(https://gumroad.com/button/button_bar.jpg) !important;
    background-repeat: repeat-x !important;
    border-radius: 4px !important;
    color: #999 !important;
    border: 0px;
    box-shadow: rgb(0 0 0 / 20%) 0 0 1px !important;
    display: inline-block !important;
    font-family: -apple-system, ".SFNSDisplay-Regular", "Helvetica Neue", Helvetica, Arial, sans-serif !important;
    font-size: 16px !important;
    font-style: normal !important;
    font-weight: 500 !important;
    line-height: 50px !important;
    padding: 0 15px !important;
    text-shadow: none !important;
    text-decoration: none !important;
}

.buyBox{
    background-color: rgb(220,220,220);
    width: 100%;
    text-align: center;
}
.buy-flex-container {
  display: flex;
  background-color: rgb(220,220,220);
  flex-direction: row;
}

.buy-flex-container > div {
  background-color: #f1f1f1;
  margin: 10px;
  padding: 20px;
  font-size: 30px;
  flex-direction: row;
}
@media (max-width: 786px) {

    .buyH2{
      font-size: 1.1em;
      text-align: center;
      font-family:Arial, Helvetica, sans-serif;
      width: 100%;
      vertical-align: middle;
    }
    .buyH3{
        font-size: 1em;
        text-align: center;
        font-family:Arial, Helvetica, sans-serif;
        width: 100%;
        vertical-align: middle;
}
.buy-flex-container {
  display: flex;
  background-color: rgb(220,220,220);
  flex-direction: column;
}

.buy-flex-container > div {
  background-color: #f1f1f1;
  margin: 10px;
  padding: 20px;
  font-size: 30px;
  flex-direction: column;
}
}