.downloadH1{
    font-size: 2em;
    font-weight: 700;
    top: 60px;
    align-content: center;
    text-align: center;
    padding-top: 60px;
}

.formtext{
    text-align: center;
    margin-top: 50px;
}

.centerform{
    align-items: center;

}

.downloadButton{
    background-color: rgb(165, 65, 18);
    columns: white;
    height: 50px;
    text-align: center;
    font-size: .7em;
    align-content: center;
    margin-top: 20px;
    border-radius: 7px;
    padding: 5px;
}