.aboutH1 {
    position: relative;
    top: 70px;
    z-index: -99;
    margin: 2em;
    text-align: center;
}

.aboutH2 {
    position: relative;
    top: 70px;
    z-index: -99;
    margin: 1em;
    font-size: 1.5em;

}
.aboutH3 {
    position: relative;
    top: 70px;
    z-index: -99;
    margin: 2em;

}